import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import { Typography } from '@mui/material';

export default function NavDrawer({ state, setState }) {

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };
  const navigate = useNavigate();
  const { logout,user} = useAuth();


  return (
    <div>
      <Drawer
        anchor='left'
        open={state}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#BF2F38",
            color: "#FFFFFF",

          }
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <img style={{padding:'16px'}} src='/assets/medicare-logo-white.svg' />
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { navigate('/') }}>
                <ListItemIcon>
                  <CreditCardIcon sx={{ color: '#FFFFFF' }} />
                </ListItemIcon>
                <ListItemText primary={'Validar Cartão'} />
              </ListItemButton>
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { navigate('/profile') }}>
                <ListItemIcon>
                  <PermIdentityOutlinedIcon sx={{ color: '#FFFFFF' }} />
                </ListItemIcon>
                <ListItemText primary={'Meu Perfil'} />
              </ListItemButton>
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              {/*               <ListItemButton onClick={() => [signout(), navigate("/")]}>
 */}
              <ListItemButton onClick={() => logout()}>
                <ListItemIcon>
                  <LogoutOutlinedIcon sx={{ color: '#FFFFFF' }} />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider/>
          <Typography style={{padding:5}} variant='body1' fontWeight={700} color="#852027" align='left'>{user?.name}</Typography>
        </Box>
      </Drawer>
    </div>
  );
}