import React, { useState } from "react";
import { Box, Paper, TextField, InputAdornment, Typography, Stack,Card, Button } from "@mui/material";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ContentLoader from '../components/ContentLoader';
import PhoneIcon from '@mui/icons-material/Phone';

import '../index.css';
import { userService } from "../services";

const validationSchema = yup.object({
    telephone: yup
        .string()
        .required('Telefone é Obrigatório.')
        .min(9, 'Deve conter mais de 9 números'),
});
const ForgotPassword = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()


    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
      }}>
            <Card>
                <Box display={'flex'} alignItems={'center'}>
                    <Box sx={{ display:'flex', flexDirection: 'column', backgroundColor: '#bf2f3805' }} alignItems='center' justifyContent='center' minHeight={200} minWidth={200} p={4} >
                    {success ?<Typography variant="h1">Código Enviado</Typography>:null}
                        <img src={success?"/assets/sms_sent.svg":"/assets/forgot-password.svg"}  width={200} height={200} />
                       
                    </Box>
                    {!success ?
                    <Box minHeight={200} maxWidth={330}>
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                telephone: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setError('')
                                setSubmitting(true);
                                setLoading(true)
                                userService.reset_password({telephone:data.telephone}).then((dt)=>{
                                    setSuccess(true)
                                  setLoading(false)
                                }).catch((err)=>{
                                  /*  setLoading(false) */
                                    console.log(err)
                                    setError('Não foi possivel recuperar a palavra-passe,Tente novamente.')
                                    setLoading(false)

                                })
                   /*              setSubmitting(false);
                                setLoading(false) */

                            }}
                        >
                            {({ isSubmitting, touched, errors, values, setFieldValue, handleChange }) => (
                                <Form style={{ textAlign: '' }} noValidate >
                                    <Box mb={4}/>
                                    <Box p={4}>
                                    <Typography align="center" variant="h5" fontWeight={900}>Recuperar Palavra Passe</Typography>
                                    <Typography align="center">Insira o seu numero de Telemóvel clique no botão Continuar</Typography>
                                    <Box mb={4}/>
                                    <Stack spacing={2}>
                                        <TextField
                                            name="telephone"
                                            placeholder="Insira o número de telefone."
                                            value={values.telephone}
                                            type='text'
                                            onChange={(e) => {
                                                setFieldValue('telephone', e.target.value.trim())
                                            }}
                                            error={
                                                touched.telephone && Boolean(errors.telephone)
                                            }
                                            helperText={touched.telephone && errors.telephone}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon color="primary" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                        <Typography color={'red'}>{error}</Typography>
                                        <Button type="submit" variant="contained" color="primary" sx={{ borderRadius: 10, padding: 1 }} disableElevation={true}>Continuar</Button>
                                    </Stack>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    :null}
                </Box>
            </Card>
            <ContentLoader open={loading} />

        </Box>
    )

}

export default ForgotPassword;