import { LoadingButton } from '@mui/lab';
import { Grid, Box, Stack, InputAdornment, Typography, Button, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import AppWrapper from '../AppWrapper';
import AlertDialog from '../components/AlertDialog';
import CardQuick from '../components/Card';
import useAuth from '../hooks/useAuth';
import * as yup from 'yup';
import { userService } from '../services';
import LockIcon from '@mui/icons-material/Lock';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Swal from 'sweetalert2';
import ContentLoader from '../components/ContentLoader';


const Profile = () => {
    const { user } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseModal = () => {
        setOpenDialog(false)
    }
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const validationSchema = yup.object({
        password: yup
            .string()
            .min(6, 'Password deve ter no minimo 6 caracteres')
            .required('Password Obrigatória.'),
    });

    return (
        <AppWrapper>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CardQuick
                            title={'Meu Perfil'}
                            sx={{ minWidth: '600px' }}

                        >
                            <Stack
                                direction='column'
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Box display={'flex'} gap={2}>
                                    <ApartmentIcon sx={{fontSize:'34px'}} color='primary' />
                                    <Typography variant='h5' component='div'>{user?.name}</Typography>

                                </Box>
                                <Button onClick={() => { setOpenDialog(true) }} variant='outlined' >Alterar Password</Button>
                            </Stack>

                        </CardQuick>

                    </Box>
                </Grid>
            </Grid>
            {openDialog ?
                <AlertDialog maxWidth='sm' onClose={handleCloseModal}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4} gap={1}>
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setError('');
                                setLoading(true)

                                userService.change_password(data.password).then(() => {
                                    setLoading(false);
                                    setOpenDialog(false);
                                    Swal.fire(
                                        'Sucesso!',
                                        'Palavra-passe alterada com Sucesso!',
                                        'success'
                                    );

                                }).catch(() => {
                                    setLoading(false);
                                    setError('Não foi Possivel alterar a palavra passe.');
                                })

                            }}
                        >
                            {({ isSubmitting, touched, errors, values, setFieldValue, handleChange }) => (
                                <Form style={{ textAlign: '' }} noValidate >
                                    <Stack spacing={4}>
                                        <Typography variant="h5">Alterar Palavra-passe</Typography>
                                        <TextField
                                            name="password"
                                            placeholder="Nova palavra-passe"
                                            type='text'
                                            fullWidth
                                            value={values.password}
                                            onChange={(e)=>{
                                                setFieldValue('password', e.target.value.trim())
                                            }}
                                            error={
                                                touched.password && Boolean(errors.password)
                                            }
                                            helperText={touched.password && errors.password}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon color="primary" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                        <LoadingButton type="submit" disableElevation sx={{ borderRadius: '14px', fontSize: '18px' }} variant="contained" loading={loading}>Alterar</LoadingButton>
                                        <Typography color={'red'}>{error}</Typography>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    <Box mb={4} />
                </AlertDialog>
                : null}
            <ContentLoader open={loading} />

        </AppWrapper>

    )
}

export default Profile;