import { Stack, Box, Card, Typography, Button, Chip } from "@mui/material";
import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { format } from 'date-fns'
import { pt } from 'date-fns/locale';

const AppointmentCard = (props) => {
    const { data } = props;
    return (
        <Card>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} color='#FFF'>
                    <Box bgcolor='#26303A' padding={2} display={'flex'} flexDirection={'column'}>
                        <Typography fontWeight={900} fontSize={30} variant="h1" mb={0}>
                            {data?.appointment_date ?
                                <>
                                    {format(new Date(data?.appointment_date), 'd')}
                                </>
                                : null}
                        </Typography>
                        <Typography fontWeight={400} variant="h4">
                            {data?.appointment_date ?
                                <>
                                    {format(new Date(data?.appointment_date), 'MMM', { locale: pt }).toLocaleUpperCase()}
                                </>
                                : null
                            }
                        </Typography>

                    </Box>
                    <Box bgcolor='#BF2F38' pt={2} pb={1} pl={2} pr={2} sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                        <Typography align="center" fontWeight={700} /* mb={0} */>
                            {data?.appointment_date ?
                                <>
                                    {format(new Date(data?.appointment_date), 'EEEE', { locale: pt }).toLocaleUpperCase()}
                                </>
                                : null
                            }
                        </Typography>
                        <Typography align="center" fontWeight={300} fontSize={40} variant="h1">
                            {data?.appointment_date ?
                                <>
                                    {format(new Date(data?.appointment_date), 'HH:mm')}

                                </>
                                : null
                            }
                        </Typography>
                    </Box>
                    <Box color={'#000'} ml={4} mt={4}>
                        <Box display={'flex'} gap={1}>
                            <Typography pt={0.5} color={'primary'} fontWeight={700}>ATO</Typography>
                            <Chip sx={{ color: '#FFF', fontWeight: 700 }} label={data.has_voucher == 1 ? 'PAGO POR MEDICARE' : 'PAGO PELO CLIENTE'} color={data.has_voucher == 1 ? 'primary' : 'success'} variant="filled" />
                        </Box>
                        <Typography variant="h4" fontWeight={300} mt={0.5}>
                            {data.subject_name}
                        </Typography>
                    </Box>
                    {
                        data.doctor_name ?
                            <Box color={'#000'} ml={4} mt={4}>
                                <Box>
                                    <Typography color={'primary'} fontWeight={700}>MÉDICO</Typography>
                                    <Typography>{data.doctor_name}</Typography>
                                </Box>
                            </Box> : null
                    }
                </Box>

                <Stack spacing={1} mr={1.5}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2} gap={1} bgcolor={'#D0F1E0'} padding={1} borderRadius={1} sx={{ width: '100%' }}>
                        <CheckIcon sx={{ fontSize: '18px', color: '#16B865' }} />
                        <Typography fontSize={10} fontWeight={700} color={'#16B865'}>REALIZADA</Typography>
                    </Box>
                   
                    {data.url?
                    <Button disableElevation color="primary2" variant="contained" sx={{ color: '#FFF', fontSize: 11 }} onClick={() => {
                        window.open(data.url, "_blank")
                    }}>
                        VER COMPROVATIVO
                    </Button>
                    :null
                    }
                    <Box />
                </Stack>

            </Box>
        </Card>
    )
}

export default AppointmentCard;