import axios from "./config";
const userService = {};
const commonService = {};



userService.login = (username, password) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post('/login', {
        username: username,
        password: password,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(error => { 
        reject(error) });
  });
}

userService.me = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .get('/me')
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.logout = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .post('auth/logout')
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}


userService.reset_password = (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .post('/password-forgotten',data)
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.change_password = (password) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .post('/password_change',{
        password: password,
      })
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.validate_customer = (card_number) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .post('/card/validate/'+card_number)
      .then(( {data} ) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.validate_appointment = (appointmentId,pin) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .post('/sheduled-appointments/validate',{
        appointment_booking_id: appointmentId,
        pin: pin,

      })
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.confirm_appointment = (id) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .post('/sheduled-appointments/confirm',{
        appointment_booking_id: id,
      })
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.card_data = (card_number) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .get('/card/details/'+card_number)
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

commonService.getSubjects = (cardNumber,filter_path='') => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .get('/subjects/'+cardNumber+'?filter_path='+filter_path)
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

commonService.createVoucherAppointment = (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
    axios
      .post('/sheduled-appointments',data)
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.validate_esquebra = (esquebraId,pin) => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .post('/esquebraUsageValidate/'+esquebraId+'/validate',{
        esquebrea_id: esquebraId,
        pin: pin,

      })
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

userService.validateCustomerService = (uuid='',pin='') => {
  return new Promise((resolve,reject) => {
    const token = localStorage.getItem('provider_token');
    axios.defaults.headers.common['X-Medicare-Key'] = token;
      axios
      .post('customer-service/validate',{
        uuid: uuid,
        pin: pin,
      })
      .then(({ data }) => { resolve(data) })
      .catch(error => { reject(error) });
  });
}

export { 
  userService,
  commonService
};

//Product service