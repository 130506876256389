import { Collapse, Stack, IconButton, Box, Card, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PinField } from "react-pin-field";
import "../pinfield.scss";
import { format } from 'date-fns'
import { pt } from 'date-fns/locale';
import ContentLoader from "../components/ContentLoader";
import { userService } from "../services";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);

const HomeServiceCard = (props) => {
    const [expand, setExpand] = useState(false);
    const [pin, setPin] = useState('');
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { data,loadData,history } = props;
   
    const onFinish = () => {
        setLoading(true);
        userService.validateCustomerService(data.uuid, pin)
        .then((data) => {
            MySwal.fire({
                title: 'Validação de Serviço',
                text: 'Pin Validado com Sucesso.',
                icon: 'success',
                buttons: false, // Add buttons for user interaction (optional)
                // Other options as needed (e.g., timer, input field)
              });
            loadData();
            setLoading(false);
        }).catch((error) => {
            console.log(error)
             setError(true);
            setLoading(false)
        })
    }
    return (
      <React.Fragment>
        <Card>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} color="#FFF">
              <Box
                bgcolor="#26303A"
                padding={2}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography fontWeight={900} fontSize={30} variant="h1" mb={0}>
                  {data?.date ? <>{format(new Date(data?.date), "d")}</> : null}
                </Typography>
                <Typography fontWeight={400} variant="h4">
                  {data?.date ? (
                    <>
                      {format(new Date(data?.date), "MMM", {
                        locale: pt,
                      }).toLocaleUpperCase()}
                    </>
                  ) : null}
                </Typography>
              </Box>
              <Box
                bgcolor="#BF2F38"
                pt={2}
                pb={1}
                pl={2}
                pr={2}
                sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
              >
                <Typography
                  align="center"
                  fontWeight={700}
                  minWidth={120} /* mb={0} */
                >
                  {data?.date ? (
                    <>
                      {format(new Date(data?.date), "EEEE", {
                        locale: pt,
                      }).toLocaleUpperCase()}
                    </>
                  ) : null}
                </Typography>
                <Typography
                  align="center"
                  fontWeight={300}
                  fontSize={40}
                  variant="h1"
                >
                  {data?.date ? (
                    <>{format(new Date(data?.date), "HH:mm")}</>
                  ) : null}
                </Typography>
              </Box>
              <Box color={"#000"} ml={4} mt={4}>
                <Box>
                  <Typography fontWeight={700} color={"primary"}>
                    Serviço ao Domicílio
                  </Typography>
                  <Typography fontWeight={300} variant="h4">
                    {data.service_type_name} ({data.subject_name})
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
              mr={2}
            >
              {history ? null : (
                <React.Fragment>
                  <Box paddingTop={1} paddingRight={2}>
                    {!expand ? (
                      <>
                        <Typography
                          color="#808080"
                          fontWeight={200}
                          align="right"
                        >
                          Validar
                        </Typography>
                        <Typography
                          color="#808080"
                          fontWeight={200}
                          align="right"
                        >
                          Código
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                  <IconButton
                    sx={{ backgroundColor: "#E5E5E5" }}
                    size="large"
                    onClick={() => {
                      setExpand(!expand);
                    }}
                  >
                    {expand ? (
                      <ExpandLessIcon sx={{ fontSize: "36px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "36px" }} />
                    )}
                  </IconButton>
                </React.Fragment>
              )}
            </Box>
          </Box>
          <Collapse in={expand}>
            <Stack mr={4} ml={4} spacing={2}>
              <Box bgcolor={"#F4F4F6"} mt={2} mb={2} p={3} borderRadius={2}>
                <Typography fontWeight={700} align="center">
                  Inserir código fornecido pelo cliente.
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <PinField
                    length={6}
                    max={6}
                    maxLength={6}
                    className="pin-field"
                    validate="0123456789"
                    inputMode="numeric"
                    onChange={setPin}
                    onComplete={() => setCompleted(true)}
                  />
                  <Button
                    variant="contained"
                    disabled={pin.length === 6 ? false : true}
                    sx={{
                      borderRadius: 30,
                      padding: 2,
                      ml: 1,
                      color: "#FFF",
                    }}
                    onClick={() => {
                      setError(false);
                      onFinish();
                    }}
                    color="success"
                    disableElevation
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    <Typography align="right">Validar Código</Typography>
                  </Button>
                </Box>
              </Box>
              {error && (
                <Box
                  display={"flex"}
                  gap={2}
                  bgcolor="#EDC0C3"
                  sx={{ color: "#C95459" }}
                  borderRadius={1}
                  padding={1}
                  mt={2}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <InfoOutlinedIcon sx={{ fontSize: "34px" }} />
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 900 }} variant="h6">
                      Código não válido.
                    </Typography>
                    <Typography fontWeight={300}>
                      Verifique se o código é o correto. Se o problema persistir
                      ligue para a Linha de Apoio Medicare <b>923 167 140</b>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box mt={2} />
            </Stack>
          </Collapse>
        </Card>
        <ContentLoader open={loading} />
      </React.Fragment>
    );
}

export default HomeServiceCard;