import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import Home from "../pages/Home";
import ForgotPassword from "../pages/ForgotPassword";
import useAuth from "../hooks/useAuth";


 const Private = ({ Item }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Item /> : <Login />;
}; 

/* const Private = ({ Item }) => {
    const { isAuthenticated } = useAuth();
    return <Item />
}; */

const UnPrivate = ({Item}) => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Item />;
    }
    return <Navigate to={'/home'} replace/>;
};


const AppRoutes = () => {

    return (
        <Routes>
            <Route exact path="home" element={<Private Item={Home}/>} />
            <Route path="/" element={<UnPrivate Item={Login} />} />
            <Route path="/reset-password" element={<UnPrivate Item={ForgotPassword} />} />
            <Route path="profile" element={<Private Item={Profile} />} />
            <Route path="*" element={<Private Item={Profile} />} />
       </Routes>
    )
}

export default AppRoutes;