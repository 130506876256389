import { Box, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import NavDrawer from "./NavDrawer";


const AppWrapper = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box mt={0.2} ml={0.2} mr={2}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Box display={'flex'}>
                                <IconButton size="large" onClick={() => { setMenuOpen(true) }}>
                                    <MenuIcon sx={{ fontSize: '42px', color: '#BF2F38' }} />
                                </IconButton>
                                <a href="/">
                                <Box sx={{ paddingRight: '135px', paddingTop: '5px' }} component={'img'} src='/assets/logo-medicare-angola.svg' width={300} height={60} />

                                </a>
                            </Box>
                            <Box>
                                <Stack direction={'row'} spacing={1} bgcolor={'#BF2F38'} color='#FFF' padding={1} paddingLeft={3} paddingRight={3} borderRadius={2} mt={1.5}>
                                    <Typography fontWeight={200}>Linha de Apoio Medicare</Typography>
                                    <img className="" src="/assets/phone.svg" width={24} height={24} />
                                    <Typography style={{ fontWeight: 900 }} component={'b'}>923 167 140</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <NavDrawer state={menuOpen} setState={setMenuOpen} />
            <Box  mr={1} ml={1} mt={1}>
                {children}
            </Box>
        </React.Fragment>

    )
}

export default AppWrapper
