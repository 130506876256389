import { ThemeProvider, createTheme, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const lightTheme = createTheme({
  typography:{
  fontFamily:'Roboto, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium:500,
  fontWeightBold: 700,
  fontWeightExtraBold: 900, 
  fontSize: 12,
  h1: {
      fontSize: '1.5rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  h2: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  h3: {
      fontSize: '1.25rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  h4: {
      fontSize: '1.1rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  h5: {
      fontSize: '1rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  h6: {
      fontSize: '.875rem',
      lineHeight: 1.2,
      fontWeight: 500,
      margin: '0 0 .5rem'
  },
  body1: {
      fontSize: '.875rem',
  }


  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: `0 0.5rem 1.25rem ${alpha('#000', .175)}` 
        },
      },
    },
     MuiChip: {
      styleOverrides: {
          sizeSmall: {
              height: 22,
              fontSize: 12,
          },
      }
  }, 
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '18px 24px'
        },
        title: {
          fontSize: '1.1rem',
          marginBottom: 0
        },
        subheader: {
          margin: '4px 0 0'
        },
        action: {
          margin: 0
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px'
        }
      }
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#BF2F38',
    },
    secondary: {
      main: '#edf2ff',
    },
    background: {
      default: '#F3F6F8'
    },
    primary2:{
      main:'#26303A',
    },
    success:{
      main:"#16B865"
    }

  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
    },
  },

});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <BrowserRouter >
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;