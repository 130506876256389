import { Grid, Box, Stack, Typography, Alert, Container, Link } from '@mui/material';
import React, { useState } from 'react';
import AppWrapper from '../AppWrapper';
import { userService } from '../services';
import CardQuick from '../components/Card';
import ContentLoader from '../components/ContentLoader';
import InputAdornments from '../components/CustomAdorments';
import CardDetail from './CardDetail';

const Home = () => {
    const [result, setResult] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [data, setData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [validationError,setValidationError]=useState('');

    async function validateCardNumber() {
        if(cardNumber.length < 9){
            setValidationError(`Número de cartão errado. Está a introduzir ${cardNumber.length} números mas o cartão Medicare tem 9 números.`)
        }
          if(cardNumber.length > 9){
        console.log("hello")

            setValidationError(`Número de cartão errado. Está a introduzir ${cardNumber.length} números mas o cartão Medicare tem 9 números.`)
        }
        if(cardNumber.length==9){

            setIsLoading(true)
            userService.validate_customer(cardNumber).then((data) => {
                setData(data);
                setResult(true)
    
            }).then(() => setIsLoading(false))
                .catch((error) => {
                    setIsError(true)
                    setIsLoading(false)
                });
        }
    }


    const handleOpenDialog = () => {
        setOpenDialog2(true)
    }

    return (
        <AppWrapper>
            <Grid container spacing={4}>
                {!result ?
                    <Grid item xs={12}>
                        <Container maxWidth='md' sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "80vh",
                        }}>
                            <Stack spacing={3}>
                           
                                <CardQuick>
                                    <Stack spacing={1} direction={'row'}>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <img src="/assets/cartoes-home.jpeg" width="270" height="270" />
                                        </Box>
                                        <Stack spacing={1} direction={'column'} >
                                           <Box>
                                            <Typography fontWeight={900} color={'primary'} mt={4} variant='h3'> VALIDAR</Typography>
                                            <Box><Typography fontWeight={300} variant='h1' style={{ borderBottom: ' 4px solid #BF2F38', paddingBottom: '2px' }} component='span'>Cartão</Typography>
                                                <Typography fontWeight={300} variant='h1' component='span'> Medicare </Typography>
                                            </Box>
                                            </Box>
                                            <br />
                                            <InputAdornments
                                                autoFocus
                                                type='text'
                                                value={cardNumber}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        setCardNumber(e.target.value);
                                                    }
                                                }}
                                                onKeyPress={(event) => {
                                                    if (event.key === "Enter") {
                                                        validateCardNumber();
                                                    }
                                                }}
                                                addormentButtonClick={() => { validateCardNumber() }} />
                                        </Stack>
                                    </Stack>
                                </CardQuick>
                                {validationError ?  <Alert style={{ fontWeight: 'bold' }} severity="warning">{validationError}</Alert>  : null}
                                {isError ? <Alert style={{ fontWeight: 'bold' }} severity="error">Ocorreu um erro ao Validar Cartão, Contacte o SAC (244) 923 167 140.</Alert> : null}

                            </Stack>
                        </Container>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <CardDetail data={data} />
                        </Grid>
                    </Grid>
                }

            </Grid>
            <ContentLoader open={isLoading} />
        </AppWrapper >

    )
}

export default Home;