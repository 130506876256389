import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLoader from '../components/ContentLoader';
import { userService } from '../services';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        userService.me().then(({data}) => {
            setUser(data);
            setIsAuthenticated(true);
            setIsLoaded(true);
        }).catch((error) => {
            if(error.response){
                localStorage.removeItem('provider_token');
                navigate('/')
                setIsLoaded(true);
            }else{
                navigate('/')
                setIsLoaded(true);
            }

        });
    }, []);


    const logout = () => {
        localStorage.removeItem('provider_token');
        setUser(null);
        setIsAuthenticated(false);
        navigate('/')
    }

    const signin = async (username, password) => {
        return new Promise((resolve, reject) => {
            userService.login(username, password)
                .then((data) => {
                    console.log(data.token);
                    localStorage.setItem('provider_token', data.token);
                    resolve(data)
                    setUser(data.user_details);
                    setIsAuthenticated(true);
                    navigate("/home", { replace: true });
                })
                .catch((error) => reject(error))
        })
    };

    return (
        <React.Fragment>
            {!isLoaded ? <ContentLoader open={true} /> :
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, logout, signin }}>
                    {children}
                </AuthContext.Provider>}
        </React.Fragment>
    )
}