import baseAxios from "axios";
export const getToken = () => localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
const DEVURL ='http://newsfa.test/api/v2/provider'
const DEV2URL ='https://app.internal.medicare.ao/api/v2/provider'
const PRODURL ='https://app.api.medicare.ao/api/v2/provider'
const axios = baseAxios.create({
    baseURL:PRODURL,
     headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'app-version':'2.0.6'
    } 
});

export default axios;

//Provider

//change app version header version each importante fix or change push