import { Box, Card, CardMedia, Grid, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import CardQuick from "../components/Card";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VoucherAppointmentCard from "./VoucherAppointmentCard";
import AppointmentCard from "./AppointmentCard";
import EsquebraCard from "./EsquebraCard";

import { userService } from "../services";
import AlertDialog from "../components/AlertDialog";
import EsquebraHistoryCard from "./EsquebraHistoryCard";
import HomeServiceCard from "./HomeServiceCard";
import useModal from "../hooks/useModal";

const CardDetail = (props) => {
    const { onReset } = props;
    const [activeTab, setActiveTab] = useState(0)
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState(props.data);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [esquebraSucessDialog, setEsquebraSucessDialog] = useState(false);
    const [currentAppointmentResult, setCurrentAppointmentResult] = useState([]);
    const [currentEsquebraResult, setCurrentEsquebraResult] = useState([]);
    const [appointmentBooking, setAppointmentBooking] = useState([]);
    const [customerServices, setCustomerServices] = useState([]);
    const [esquebras, setEsquebras] = useState([]);
    const [prescriptions, setPrescriptions] = useState([]);
    const [history, setHistory] = useState([]);

    const handleCloseModal = () => {
        setOpenDialog(false)
    }

    const handleOpenModal = () => {
        setOpenDialog(true)
    }

    const handleOpenDialog = () => {
        setOpenDialog2(true)
    }

    const handleDialogResult = value => {
        setCurrentAppointmentResult(value);
    }

    const handleEsquebraDialogResult = value => {
        setCurrentEsquebraResult(value);
    }

    const handleCloseEsquebraSuccessDialog = () => {
        setEsquebraSucessDialog(false)
    }

    const handleOpenEsquebraSuccessDialog = () => {
        setEsquebraSucessDialog(true)
    }


    useState(() => {
        userService.card_data(data.card).then((data) => {
            setAppointmentBooking(data.appointmentBooking);
            setPrescriptions(data.prescriptions);
            setHistory(data.history);
            setEsquebras(data.esquebras)
            setCustomerServices(data.services);
        });
    }, [])

    const loadData = () => {
        userService.card_data(data.card).then((data) => {
            setAppointmentBooking(data.appointmentBooking);
            setPrescriptions(data.prescriptions);
            setHistory(data.history);
            setEsquebras(data.esquebras);
            setCustomerServices(data.services);
        });
    }

    return (
        <React.Fragment>
            <Grid item xs={12} xl={3} md={3}>
                <CardQuick>
                    <Stack spacing={2}>
                        <CardMedia
                            component="img"
                            image={data?.image}
                        />
                        <Box bgcolor={data.valid ? '#16B865' : '#B81516'} color='#fff' borderRadius={1} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1} pt={1} pb={1}>
                            {data.valid ? <CheckCircleOutlineIcon sx={{ fontSize: '32px' }} /> : <DoNotDisturbIcon sx={{ fontSize: '32px' }} />}<Typography fontWeight={900}>{data.valid ? 'CARTÃO ATIVO' : 'CARTÃO NÃO AUTORIZADO'}</Typography>
                        </Box>
                        <Box ml={2}>
                            <Typography color='primary' variant="h4">BENEFICIÁRIO</Typography>
                            <Typography variant="h3" fontWeight={300}>{data?.name}</Typography>
                            <Box mb={1.5} />
                            <Typography color='primary' fontWeight={700}>NÚMERO DE CARTÃO</Typography>
                            <Typography variant="h4" fontWeight={300}>{data?.card}</Typography>
                            <Box mb={1.5} />
                            <Typography color='primary' fontWeight={700}>PLANO</Typography>
                            <Typography variant="h4" fontWeight={300}>{data?.plan}</Typography>
                            <Box mb={1.5} />
                            <Typography color='primary' fontWeight={700}>FIM DE CONTRATO</Typography>
                            <Typography variant="h4" fontWeight={300}>{data?.contract_end}</Typography>
                            <Box mb={1.5} />
                        </Box>
                    </Stack>
                </CardQuick>
            </Grid>
            <Grid item xs={12} xl={6} md={7}>
               {/*  <NewVoucherAppointmentCard data={data}/>  */}
                <Box mt={5} display={'flex'} justifyContent={'space-between'}>
                    <Box onClick={() => { setActiveTab(0) }}>
                        <Typography style={{ borderBottom: `3px solid ${activeTab === 0 ? '#BF2F38' : '#26303A'}`, cursor: 'pointer', paddingBottom: 3 }} fontWeight={900} align="center" variant="h5" >AGENDAMENTOS</Typography>
                    </Box>
                    <Box>
                        <Typography align="center" style={{ borderBottom: `3px solid ${activeTab === 1 ? '#BF2F38' : '#26303A'}`, cursor: 'pointer', paddingBottom: 3 }} onClick={() => { setActiveTab(1) }}>HISTÓRICO</Typography>
                    </Box>
                </Box>
                {activeTab === 0 ?
                    <Box>
                        <Box mt={3} />
                        {appointmentBooking?.length == 0 ?
                            <React.Fragment>
                                <Box display={'flex'} gap={2} bgcolor='#75B5FF' borderRadius={1} padding={1} mt={2}>
                                    <Box component={'img'} src="/assets/circle-info.svg" width={34} height={34} mt={1} />
                                    <Box>
                                        <Typography sx={{ fontWeight: 900 }} variant="h6">Não existem actos ativados ou agendados pelo cliente.</Typography>
                                        <Typography>Efectuar os descontos convencionados para clientes Medicare.</Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>

                            :
                            <>
                                {appointmentBooking?.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <VoucherAppointmentCard handleDialogResult={handleDialogResult} handleOpenDialog={handleOpenDialog} handleOpenModal={handleOpenModal} loadData={loadData} data={item} />
                                        <Box mt={2} />
                                    </React.Fragment>
                                ))}
                            </>
                        }
                         <Box mt={3} />
                        {
                        customerServices?.map((item,index)=>(
                            <HomeServiceCard  history={false} key={item.id} data={item} loadData={loadData}  />
                           ))
                        }
                        {esquebras?.map((item, index) => (
                            <EsquebraCard handleDialogResult={handleEsquebraDialogResult} loadData={loadData} handleCloseEsquebraSuccessDialog={handleCloseEsquebraSuccessDialog} handleOpenDialog={handleOpenEsquebraSuccessDialog} key={item.id} data={item} />
                        ))}

                        <Box mt={3} />
                    </Box>
                    :
                    <Box>
                        <Box mt={3} />
                        {history?.appointmentBooking?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <AppointmentCard data={item} />
                                <Box mt={2} />
                            </React.Fragment>
                        ))}
                        {history?.services?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <HomeServiceCard history={true} data={item} />
                                <Box mt={2} />
                            </React.Fragment>
                        ))}
                        {history?.esquebras?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <EsquebraHistoryCard url={item.url} title={item.title} conditionText={item.condition_text} />
                                <Box mt={2} />
                            </React.Fragment>
                        ))}

                    </Box>
                }
            </Grid>
            {prescriptions ?
                <Grid item xs={8} xl={2} md={2}>
                    <Box display={'flex'}>
                        <Typography style={{ borderBottom: `3px solid #BF2F38`, paddingBottom: 3 }} fontWeight={900} align="left" variant="h5" >RECEITAS</Typography>

                    </Box>
                    {prescriptions?.map((item, index) => (
                        <Card key={item.name} sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2, justifyContent: 'flex-start' }}>
                            <Box sx={{ backgroundColor: '#26303A' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Box component={'img'} src="/assets/prescription.svg" width={40} height={40} pl={1} />
                            </Box>
                            <Box mt={2} mb={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                <Box>
                                    <Typography fontWeight={900} color='primary'>RECEITA Nº {item.id}</Typography>
                                    <Typography fontWeight={30} variant="subtitle1">{item.date}</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title='Abrir Receita'>
                                        <IconButton
                                            onClick={() => window.open(item.url, "_blank")}
                                        >
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </Tooltip>

                                </Box>

                            </Box>

                        </Card>
                    ))}

                </Grid>
                : null
            }
            <AlertDialog open={openDialog} onClose={handleCloseModal}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4} gap={1}>
                    <CheckCircleOutlineIcon sx={{ fontSize: '64px', color: '#16B865' }} />
                    <Typography fontWeight={900} variant="h2" color={'#16B865'}>Código validado com sucesso!</Typography>
                </Box>
                <Box bgcolor={'#75B5FF'} padding={2} borderRadius={1} mt={4}>
                    <Typography align="center" variant="h1" fontWeight={700}>CONSULTA PAGA POR MEDICARE</Typography>
                    <Typography align="center" variant='h2' fontWeight={700}>NÃO COBRAR AO CLIENTE, NÃO EMITIR FATURA.</Typography>
                    <Typography align="center" fontWeight={300} onClick={() => { }}>Solicitar assinatura ao cliente.</Typography>
                </Box>
                <Box mt={1} />
                <Box display={'flex'} alignItems={'flex'} justifyContent={'center'}>
                    <Link
                        component="button"
                        variant="h4"
                        onClick={() => window.open(currentAppointmentResult.filename, "_blank")}
                        align="center"
                        color={'#000'}
                    >
                        Ver Comprovativo
                    </Link>
                </Box>
                <Box mb={4} />
            </AlertDialog>

            {openDialog2 ?
                <AlertDialog onClose={handleCloseModal}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4} gap={1}>
                        <CheckCircleOutlineIcon sx={{ fontSize: '64px', color: '#16B865' }} />
                        <Typography fontWeight={900} variant="h5" color={'#16B865'}>Consulta confirmada com sucesso!</Typography>
                    </Box>
                    <Box bgcolor={'#75B5FF'} padding={2} borderRadius={1} mt={4}>
                        <Typography align="center" variant="h4" fontWeight={700}>CONSULTA PAGA POR CLIENTE</Typography>
                        <Typography align="center" fontWeight={700}>COBRAR AO CLIENTE COM DESCONTO CONVENCIONADO</Typography>
                    </Box>
                    <Box mt={1} />
                    <Box mb={4} />
                </AlertDialog>
                : null}
            {esquebraSucessDialog ?
                <AlertDialog open={esquebraSucessDialog} maxWidth="md" onClose={handleCloseEsquebraSuccessDialog}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={4} gap={1}>
                        <CheckCircleOutlineIcon sx={{ fontSize: '64px', color: '#16B865' }} />
                        <Typography fontWeight={900} variant="h5" color={'#16B865'}>Esquebra Validado com sucesso!</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'flex'} justifyContent={'center'}>
                        <Link
                            component="button"
                            variant="h4"
                            onClick={() => window.open(currentEsquebraResult.filename, "_blank")}
                            align="center"
                            color={'#000'}
                        >
                            Ver Comprovativo
                        </Link>
                    </Box>
                    <Box mt={1} />
                    <Box mb={4} />
                </AlertDialog>
                : null}

        </React.Fragment>
    )
}

export default CardDetail;