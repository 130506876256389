import { Box, Button, Card, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';

const EsquebraHistoryCard = (props) => {
    return (
        <React.Fragment>
            <Card sx={{ mt: 2 }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} color='#FFF'>
                        <Box bgcolor='#26303A' pt={2} pb={1} pl={2} pr={2} sx={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                            <Box component={'img'} src='/assets/esquebra.svg' width={80} height={80} />
                        </Box>
                        <Box color={'#000'} ml={4} mt={4}>
                            <Box>
                                <Typography fontWeight={700} color={'primary'}>OFERTA</Typography>
                                <Typography fontWeight={300} variant="h4">{props.title}</Typography>
                            </Box>
                        </Box>
                        <Box maxWidth={600} color={'#000'} ml={4} mt={4}>
                            <Box>
                                <Typography fontWeight={700} color={'primary'}>DESCRIÇÃO</Typography>
                                <Typography fontWeight={300} variant="subtitle1">{props.conditionText}</Typography>
                            </Box>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} color={'#000'} ml={4} mr={2}>
                            <Tooltip title={'Descarregar Comprovativo'}>
                                <IconButton variant="contained" onClick={() => {
                                    window.open(props.url, "_blank")
                                }}>
                                    <DownloadIcon color="primary" />
                                </IconButton>
                            </Tooltip>

                        </Box>
                    </Box>

                </Box>

            </Card>
        </React.Fragment>
    )
}

export default EsquebraHistoryCard;