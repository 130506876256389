import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function AlertDialog({children,maxWidth='md',onClose,open}) {


  return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth= {maxWidth}
        fullWidth
        PaperProps={{
          style: { borderRadius: 8 }   }}
        
      >
        <DialogContent>
            {children}
        </DialogContent>
      </Dialog>
  );
}
