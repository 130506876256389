import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Tooltip } from '@mui/material';

const TextField = styled(MuiTextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        paddingRight: 0,
        width: '350px'
    },
    '& .MuiInputAdornment-root': {
        backgroundColor: '#BF2F38',
        padding: '36px 14px',
        borderTopLeftRadius: theme.shape.borderRadius + 'px',
        borderBottomLeftRadius: theme.shape.borderRadius + 'px',
        borderTopRightRadius: theme.shape.borderRadius + 'px',
        borderBottomRightRadius: theme.shape.borderRadius + 'px',
    },
}));

export default function InputAdornments(props) {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <TextField
                autoFocus
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '28px',
                        fontWeight: 'bold',
                        color:'#464646'
                    },
                }}
                {...props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title='Validar Cartão'>
                                <IconButton sx={{ color: '#FFF' }} onClick={props.addormentButtonClick}>
                                    <ArrowForwardIosIcon sx={{ fontSize: '42px' }} />
                                </IconButton>
                            </Tooltip>

                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}
