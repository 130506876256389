import React, { useState } from "react";
import { Box, TextField, InputAdornment, Typography, Stack, IconButton, Tooltip, Link, Card, Button } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import ContentLoader from '../components/ContentLoader';
import '../index.css';

const validationSchema = yup.object({
    username: yup
        .string()
        .required('Utilizador é Obrigatório.')
        .min(3, 'Deve conter mais de 3 dígitos'),
    password: yup
        .string()
        .required('Password Obrigatória.'),
});
const Login = () => {
    const { signin } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleClickShowPassword = () => {

        setShowPassword(!showPassword);
    };
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            background: 'transparent linear-gradient(90deg, #0f172a 0%, #1e293b 100%) 0% 0% no-repeat padding-box',
        }}>
            <Card>
                <Box display={'flex'} alignItems={'center'}>
                    <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' }, flexDirection: 'column', backgroundImage: "url('/assets/shapeWhite.svg')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} alignItems='center' justifyContent='center' minHeight={600} minWidth={600} bgcolor='#0f172a' p={4}>
                        {/*  <img src=""/> */}
                        <Typography variant="h1" fontWeight={900} color={'white'}>Ainda não é Prestador?</Typography>
                        <Button href="https://medicare.ao"
                            target="_blank" variant="outlined" sx={{ color: '#FFFFFF', fontWeight: 900, fontSize: 15, borderRadius: 6, borderColor: '#FFFFFF' }}>SAIBA MAIS</Button>

                    </Box>
                    <Box minHeight={400} minWidth={400}>


                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setLoading(true)

                                signin(data.username, data.password)
                                    .then(() => {
                                        setLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        console.log("no catch");
                                        setError('Utilizador ou Password Incorretos.');
                                        setLoading(false)
                                    })

                            }}
                        >
                            {({ isSubmitting, touched, errors, values, setFieldValue, handleChange }) => (
                                <Form style={{ textAlign: '' }} noValidate >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                        <Stack spacing={2} alignItems={'center'}>
                                            <Box component={'img'} src='/assets/logo-medicare-angola.svg' width={150} height={50} />
                                            <Typography fontWeight={700} variant="h3">Bem-Vindo</Typography>
                                            <Typography variant="h4" fontWeight={300}>Autentique-se para entrar no Portal do Prestador</Typography>


                                        </Stack>
                                        <Box mb={4} />
                                        <Stack spacing={4}>
                                            <TextField
                                                label="Nome de Utilizador"
                                                name="username"
                                                value={values.username}
                                                onChange={(e) => {
                                                    setFieldValue('username', e.target.value.trim())

                                                }}
                                                error={
                                                    touched.username && Boolean(errors.username)
                                                }
                                                helperText={touched.username && errors.username}
                                                sx={{
                                                    '& label': { paddingLeft: (theme) => theme.spacing(2) },
                                                    /*     '& input': {fontSize:10 }, */
                                                    '& fieldset': {
                                                        paddingLeft: (theme) => theme.spacing(2.5),
                                                        borderRadius: '30px',
                                                    },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircleIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                variant="outlined"
                                                label="Palavra-passe"
                                                sx={{
                                                    '& label': { paddingLeft: (theme) => theme.spacing(2) },
                                                    '& fieldset': {
                                                        paddingLeft: (theme) => theme.spacing(2.5),
                                                        borderRadius: 8,
                                                    },
                                                }}
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={(e) => {
                                                    setFieldValue('password', e.target.value.trim())
                                                }}
                                                error={
                                                    touched.password && Boolean(errors.password)
                                                }
                                                helperText={touched.password && errors.password}

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title={showPassword ? "Esconder Password" : "Mostrar password"}>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOffIcon color="primary" /> : <VisibilityIcon color="primary" />}

                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Typography align="center" color={'red'}>{error}</Typography>
                                            <LoadingButton type="submit" disableElevation x={{ borderRadius: 8, fontSize: 15 }} variant="contained" loading={loading}>Entrar</LoadingButton>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    navigate('/reset-password')
                                                }}
                                            >
                                                Esqueceu sua Palavra-passe?
                                            </Link>
                                        </Stack>

                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Card>
            <ContentLoader open={loading} />

        </Box>
    )

}

export default Login;